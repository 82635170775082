import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import ReactMarkdown from "react-markdown"
export default ({ data }) => {
    const { markdownRemark } = data
    const { frontmatter, rawMarkdownBody } = markdownRemark
    console.log(frontmatter)
    return (
      <Layout>
        <SEO title='About' description='This is the about page' />
        <section className='about-page'>
          <div className='section-center about-center'>
            <Image fluid={frontmatter.image.childImageSharp.fluid} className='about-img' />
            <article className='about-text'>
              <Title title={frontmatter.title} />
              <ReactMarkdown source={rawMarkdownBody} />
              <div className='about-stack'>
                {frontmatter.stack.map((item, index) => {
                  return <span key={index}>{item.title}</span>
                })}
              </div>
              <Link to='/newsletter/' className='btn margin-top'>Newsletter Signup</Link>
            </article>
          </div>
        </section>
      </Layout>
    )
}

export const query = graphql`
  {
    markdownRemark(frontmatter: {title: {eq: "About"}}) {
      frontmatter {
        title
        stack {
          title
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      rawMarkdownBody
    }
  }
`


